import React, { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { withFormikDevtools } from 'formik-devtools-extension';
import {
  Box,
  Divider,
  FormikOnChangeWithDebounce,
  LinkCore,
  MultiLanguageTextField,
  Stack,
  Typography,
} from '@egym/ui';
import { SimpleTitleCustomizedWidgetPreferencesFormProps } from './SimpleTitleCustomizedWidgetPreferencesFormProps';

const SimpleTitleCustomizedWidgetPreferencesForm: FC<SimpleTitleCustomizedWidgetPreferencesFormProps> = ({
  formProps,
  availableLanguages,
  openLanguagesMenuTab,
  onFormChanged,
}) => {
  const { t } = useTranslation();

  return (
    <Formik {...formProps}>
      {props =>
        withFormikDevtools(
          props,
          <Form>
            <FormikOnChangeWithDebounce onFormChanged={onFormChanged} debounce={300} />
            <Stack spacing={7} direction="column" sx={{ px: 8, maxWidth: '710px', boxSizing: 'border-box' }}>
              <Stack spacing={5} sx={{ pt: 7 }}>
                <Typography variant="h5Bold">{t('appDesign.labels.widgets.name') as ReactNode}*</Typography>
                <MultiLanguageTextField name="title" languages={availableLanguages} inputProps={{ maxLength: 21 }} />
              </Stack>
              <Divider flexItem />
              <Box sx={{ mt: 10 }}>
                <Typography variant="body2" sx={{ color: 'grey.500' }}>
                  <Trans
                    i18nKey="appDesign.labels.customizeLayout.tabsSettingsDescription"
                    components={[
                      <LinkCore
                        component="button"
                        sx={{ textDecoration: 'underline' }}
                        color="inherit"
                        onClick={() => openLanguagesMenuTab(props)}
                      />,
                    ]}
                  />
                </Typography>
              </Box>
            </Stack>
          </Form>,
        )
      }
    </Formik>
  );
};

export default SimpleTitleCustomizedWidgetPreferencesForm;
