import React, { forwardRef, ReactNode } from 'react';
import { Box } from '@mui/material';
import { Paper, Stack } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '@components';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';

const PreviewGenius = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx, previewerState, widgetProps }, ref) => {
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          palette={palette}
          title={
            widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] || 'appPreviewer.labels.genius'
          }
        />
        <Paper
          sx={{
            textAlign: 'left',
            fontSize: '14px',
            color: 'rgb(33,33,33)',
            borderRadius: '12px',
            boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
          }}
        >
          <Stack spacing={0}>
            <Box
              component="div"
              sx={{
                width: '100%',
                borderRadius: '8px',
                overflow: 'hidden',
                position: 'relative',
                boxShadow: '0',
                ...wrapperSx,
              }}
            >
              <div
                style={{
                  background: 'linear-gradient(0deg, rgb(255, 255, 255), rgba(0, 0, 0, 0.0))',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                }}
              >
                <Stack
                  direction="column-reverse"
                  sx={{ height: '100%', width: '250px', px: '20px', py: '16px', boxSizing: 'border-box' }}
                >
                  <span
                    style={{
                      fontSize: '28px',
                      lineHeight: '34px',
                      color: 'black',
                      fontWeight: 700,
                      marginBottom: '4px',
                    }}
                  >
                    {tFunction('appPreviewer.labels.geniusCallToAction') as ReactNode}
                  </span>
                  <span
                    style={{
                      fontSize: '16px',
                      lineHeight: '21px',
                      color: 'rgba(117, 117, 117, 1)',
                      fontWeight: 700,
                      marginBottom: '10px',
                    }}
                  >
                    {tFunction('appPreviewer.labels.geniusWidgetName') as ReactNode}
                  </span>
                </Stack>
              </div>
              <img
                src="/images/previewerDefaultImages/features/genius.png"
                style={{ height: '100%', width: '100%', objectFit: 'scale-down' }}
                alt="genius"
              />
            </Box>
            <Box
              component="div"
              sx={{
                width: '100%',
                height: '112px',
                borderRadius: '8px',
                overflow: 'hidden',
                position: 'relative',
                boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
                ...wrapperSx,
              }}
            >
              <Stack direction="column" sx={{ px: '20px' }}>
                <span
                  style={{
                    width: '200px',
                    fontSize: '12px',
                    lineHeight: '17px',
                    color: 'rgb(76, 74, 74)',
                    marginTop: '4px',
                    marginBottom: '14px',
                  }}
                >
                  {tFunction('appPreviewer.labels.geniusHint') as ReactNode}
                </span>
                <Paper
                  sx={{
                    height: '40px',
                    borderRadius: '20px',
                    background: palette.primary,
                    fontSize: '14px',
                    fontWeight: 700,
                    color: 'white',
                  }}
                  elevation={0}
                >
                  <Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                    <span style={{ lineHeight: '14px' }}>
                      {tFunction('appPreviewer.labels.getStarted') as ReactNode}
                    </span>
                  </Stack>
                </Paper>
              </Stack>
            </Box>
          </Stack>
        </Paper>
      </PreviewContainer>
    );
  },
);

export default PreviewGenius;
